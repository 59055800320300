.menu {
    color: var(--black-text-color);
    margin-top: 10vh;;
}

.menu-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(../images/menuBackground.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;  
    z-index:-3 
}

.menu-container {
    text-align: center;
}

.dish {
    color: var(--black-text-color);
    font-size: 1.25rem;
    text-align: left;
    margin: 0.5rem 0;
    font-weight: 400;
}

.price {
    text-align: end;
}