.snap-container {
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.snap-container > div {
  scroll-snap-align: start;
}

/*  Carousel */

.carousel {
  position: sticky;
}

.carouselPics {
  margin: auto;
  margin-top: 6vh;
  width: 100vw;
  height: 95vh;
  object-fit: cover;
  position: static;
}

.pizarra {
  object-fit: fill;
}

.homeQuote-background {
  background-image: url("../images/homeQuoteBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: -2;
  position: sticky;
  top: 0;
}

.homeQuote {
  top: 0;
  font-size: 2rem;
  color: var(--black-text-color);
}

.homeQuote-background-overlay {
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
  height: 100vh;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .homeQuote {
    font-size: 1.4rem;
  }
}
