.confirm-background {
    background-color: var(--main-background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--main-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}